const devUri = {
    sso:'http://test.signin.askbot.cn',
    portal:'https://portal.test.askbot.cn/#/auth',
    test:'https://portal.test.askbot.cn/#/accountManage',
    workorder:'http://workorder.test.askbot.cn/#/auth',
    open:'https://test.open.askbot.cn/#/auth',
    im:'https://test.im.askbot.cn'
};
const prodUri = {
    sso:'https://signin.askbot.cn',
    portal:'https://portal.askbot.cn/#/auth',
    workorder:'https://workorder.askbot.cn/#/auth',
    test:'https://portal.askbot.cn/#/accountManage',
    open:'https://open.askbot.cn/#/auth',
    im:'https://im.askbot.cn'
};
const rediect = {
    development:devUri,
    test:devUri,
    production:prodUri,
    test_production:devUri,
};

export  {rediect}